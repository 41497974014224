import planejamentoDefinicaoPassos from '@/spa/planejamento-acao-massivo/form/planejamentoDefinicaoPassos';

export default {
  name: 'PlanejamentoAcaoFormPassosMixin',
  components: {
    ...planejamentoDefinicaoPassos.componentes,
  },
  data() {
    return {
      planejamentoDefinicaoPassos,
    };
  },
  computed: {
    componentesPassos() {
      return Object.keys(this.passosHabilitados)
        .filter((nome) => this.passosHabilitados[nome].habilitado)
        .map((nome) => ({
          nome,
          componente: this.passosHabilitados[nome].componente,
        }));
    },
    passosHabilitados() {
      return planejamentoDefinicaoPassos
        .passosHabilitados(this, this.configuracao.configuracao, this.acaoEdicao);
    },
  },
  methods: {
    executarEvento(evento) {
      this.componentesPassos.map((c) => c.nome).forEach((ref) => {
        const [referencia] = this.$refs[ref];
        if (referencia.onEvent) {
          referencia.onEvent(evento);
        }
      });
      evento.executarDoneAcaoForm();
    },
    executarEventoEncadeadoTodosPassos(evento) {
      const passosEvento = this.componentesPassos.map((c) => c.nome);
      this.executarEventoEncadeado(evento, passosEvento);
    },
    montarExecucaoEncadeada(evento) {
      const passosEvento = this.componentesPassos.map((c) => c.nome);
      passosEvento.pop();
      this.executarEventoEncadeado(evento, passosEvento);
    },
    executarEventoEncadeado(evento, passosEvento) {
      let primeiroPasso;
      for (let i = 0; i < passosEvento.length; i += 1) {
        const [referencia] = this.$refs[passosEvento[i]];
        if (referencia.onChainedEvents) {
          if (!primeiroPasso) {
            primeiroPasso = referencia;
          } else {
            evento.pushProximoPasso(referencia);
          }
        }
      }
      if (primeiroPasso) {
        primeiroPasso.onChainedEvents(evento);
      } else {
        evento.executarDoneAcaoForm();
      }
    },
  },
};
